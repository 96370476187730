import { useContext, useEffect, useState } from "react";
import { AuthStateContext } from "../../Context/MainContext";
import i18n from "../../Utils/i18n/i18n";

import { v4 as uuidv4 } from "uuid";
import uploadFileToBlob from "../../Utils/upload_azure_storage";
import { ClientData, getApplicationUsers } from "../../Context/Actions/clients";
import { patterns } from "../../Utils/patterns";
import { useTranslation } from "react-i18next";
import { ConfigProvider, Skeleton, Table } from "antd";
import styles from "./index.module.scss";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";
import { ColumnGroupType, ColumnsType } from "antd/lib/table";
import { ColumnType } from "antd/lib/list";
import { AnyObject } from "antd/lib/_util/type";
import { Link } from "react-router-dom";
const ClientListingTable = ({
  data,
  sortDirections,
  setSortDirections,
  loading,
  pagination,
  setPagination,
}: any) => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();

  const {
    state: { lookups, brands },
    dispatch,
  } = useContext(AuthStateContext);

  let columns: ColumnGroupType<AnyObject> | ColumnsType<AnyObject> = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: true,
      fixed: dir() == "ltr" ? "left" : "right",
      render: (id: any) => {
        return loading ? (
          <Skeleton.Input active style={{ height: "17px" }} />
        ) : (
          <span dir="ltr">{`${t("cli")}-${id}`}</span>
        );
      },
    },
    {
      title: t("name/legal"),
      dataIndex: "name",
      key: "name",
      render: (a: any, _) => {
        return loading ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Skeleton.Input active style={{ minWidth: '100px', width: "70%", height: "17px" }} />
            <Skeleton.Input active style={{ minWidth: '70px', width: "50%", height: "17px" }} />
          </div>
        ) : (
          <div className={styles.name}>
            <Link to={`/clients/${_.id}`} className="changeOnHover">
              {a.name}
            </Link>
            <div>
              <p>{a.legalName}</p>
            </div>
          </div>
        );
      },
      sorter: true,
      fixed: dir() == "ltr" ? "left" : "right",
    },
    {
      title: t("brands"),
      dataIndex: "brands",
      key: "brands",
      render: (brands: any) =>
        loading ? (
          <Skeleton.Button style={{ width: "65px", height: "65px" }} active />
        ) : brands?.length ? (
          <ul className={styles.brands}>
            {brands?.slice(0, 2).map((b: any, i: any) => (
              <li key={i}>
                <img src={b.img} alt="" />
              </li>
            ))}
            {brands?.length > 2 && (
              <li className={styles.count}>+{brands.length - 2}</li>
            )}
          </ul>
        ) : (
          <span className="alignText">-</span>
        ),
    },
    {
      title: t("account_manager"),
      dataIndex: "accountManager",
      key: "owner",
      render: (owner: any) =>
        loading ? (
          <Skeleton avatar paragraph={{ rows: 0 }} active />
        ) : owner ? (
          <div className={styles.user}>
            <div className={styles.userImg}>{`${owner.trim().split(" ").length > 1
              ? owner.trim().split(" ")[0][0] + owner.trim().split(" ")[1][0]
              : owner.trim().split(" ")[0][0]
              }`}</div>
            {owner}
          </div>
        ) : (
          <span className="alignText">-</span>
        ),
      sorter: true,
    },
    {
      title: t("campaigns"),
      dataIndex: "campaigns",
      key: "campaignsCount",
      sorter: true,
      render: (a: any) =>
        loading ? (
          <Skeleton.Input
            active
            style={{ minWidth: "50%", maxWidth: "50%", height: "17px" }}
          />
        ) : a ? (
          a
        ) : (
          <span className="alignText">-</span>
        ),
    },
    {
      title: t("accepted_campaigns"),
      dataIndex: "acceptedCampaigns",
      key: "acceptedCampaignsCount",
      render: (obj: any) =>
        loading ? (
          <Skeleton.Input
            active
            style={{ minWidth: "50%", maxWidth: "50%", height: "17px" }}
          />
        ) : obj.a && obj.b ? (
          <div className={styles.acceptedCampaign}>
            <span>{obj.a}</span>
            <span>{obj.b}</span>
          </div>
        ) : (
          <span className="alignText">-</span>
        ),
      sorter: true,
    },
    {
      title: t("agency_fees"),
      dataIndex: "fees",
      key: "agencyFees",
      sorter: true,
      render: (a: any) =>
        loading ? (
          <Skeleton.Input
            active
            style={{ minWidth: "50%", maxWidth: "50%", height: "17px" }}
          />
        ) : typeof a == "number" ? (
          `${a}%`
        ) : (
          <span className="alignText">-</span>
        ),
    },
    {
      title: t("creation_date"),
      dataIndex: "creationDate",
      key: "creationDate",
      className: "fs_12",
      sorter: true,
      render: (a) =>
        loading ? (
          <Skeleton.Input
            active
            style={{ minWidth: "50%", maxWidth: "50%", height: "17px" }}
          />
        ) : (
          a
        ),
    },
    {
      title: t("vat"),
      dataIndex: "vat",
      key: "vatNumber",
      sorter: true,
      render: (a: any) =>
        loading ? (
          <Skeleton.Input
            active
            style={{ minWidth: "50%", maxWidth: "50%", height: "17px" }}
          />
        ) : a ? (
          a
        ) : (
          <span className="alignText">-</span>
        ),
    },
  ];

  console.log(sortDirections, "sort directions");

  return (
    <div className={styles.tableContainer}>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              borderColor: "#EEE",
              rowHoverBg: "#F5F6FF",
              headerColor: "#1F1F1F",
              headerSplitColor: "transparent",
            },
          },
        }}
      >
        {columns && (
          <Table
            className="clientsTable"
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: pagination?.pageSize,
              total: pagination?.total,
              current: pagination?.page,
              onChange(page, pageSize) {
                setPagination((prev: any) => ({ ...prev, page, pageSize }));
              },
              showSizeChanger: false,
            }}
            scroll={{ x: 1216, scrollToFirstRowOnChange: true }}
            showSorterTooltip={false}
            onChange={(p, f, s: any) => {
              setSortDirections((prev: any) => ({
                name: [s.columnKey],
                direction: s.order ? s.order : "",
              }));
            }}
            columns={data.length ? columns : []}
            dataSource={data}
          />
        )}
      </ConfigProvider>
    </div>
  );
};

export default ClientListingTable;
