import {
  BlobServiceClient,
  ContainerClient,
  BlockBlobClient,
} from "@azure/storage-blob";
import { v4 as uuidv4 } from "uuid";

const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN;
const storageAccountName = process.env.REACT_APP_AZURE_ACCOUNT_NAME;

export const isStorageConfigured = () => {
  return !storageAccountName || !sasToken ? false : true;
};

const uploadFileToBlob = async (
  rootFolder: string,
  file: File | null,
  onProgress?: (percent: number) => void
): Promise<string> => {
  if (!file) {
    return "";
  }

  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  const containerClient: ContainerClient = blobService.getContainerClient(
    `${containerName}/${rootFolder}` as string
  );

  // Generate a unique blob name
  const blobName = `${uuidv4()}-${file.name}`;
  const blockBlobClient: BlockBlobClient =
    containerClient.getBlockBlobClient(blobName);

  try {
    // Create a new XMLHttpRequest to upload the file and handle progress
    const xhr = new XMLHttpRequest();
    const uploadUrl = blockBlobClient.url;

    xhr.open("PUT", uploadUrl, true);
    xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
    xhr.setRequestHeader("x-ms-blob-content-type", file.type);

    // Handle progress event
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable && onProgress) {
        const percent = (event.loaded / event.total) * 100;
        onProgress(percent);
      }
    };

    // Handle upload completion
    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const url = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${rootFolder}/${blobName}`;
          resolve(url);
        } else {
          reject(new Error(`Upload failed with status: ${xhr.status}`));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during upload"));

      xhr.send(file);
    });
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export default uploadFileToBlob;
